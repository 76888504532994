import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carga-proceso',
  templateUrl: './carga-proceso.component.html',
  styleUrls: ['./carga-proceso.component.scss']
})
export class CargaProcesoComponent implements OnInit {

  @Input() titulo: string = '';
  @Input() cargadas: number = 0;
  @Input() total: number = 0;

  constructor() { }

  ngOnInit() {
  }

  getPercent(): number {
    return Math.round((this.cargadas * 100) / this.total) || 0;
  }

}
