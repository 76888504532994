import { Component,OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'circular-progress',
  template: `
    <mat-card>
      <h2 [ngStyle] = "showCargaCamaras() ? {'font-size': '12px'}: {'font-size': '16px'}">
        Porcentaje general de progreso:</h2>
    <div
      [ngStyle] = "showCargaCamaras() ? {'font-size': '110px'}: {'font-size': '140px'}"
       class="c100 p{{valorProgreso}} small">
        <span>{{valorProgreso}}%</span>
        <div class="slice">
            <div class="bar"></div>
            <div class="fill"></div>
        </div>
      </div>
      <app-carga-proceso *ngIf="showCargaCamaras()" [titulo]="'CÁMARAS CARGADAS'" [cargadas]="cargadasCamaras" [total]="totalCamaras"></app-carga-proceso>
      <a  *ngIf="!showCargaCamaras()" (click)="irResumen();" class="btn btnResumen col-sm-12">Resumen</a>
    </mat-card>
  `,
  styleUrls: ['./circularprogress.scss']
})

export class CircularProgressComponent implements OnInit {

  @Input() valorProgreso: number = 0;
  @Input() totalCamaras: number = 0;
  @Input() cargadasCamaras: number = 0;

  constructor(private _router: Router){}

  showCargaCamaras(): boolean {
    return this.cargadasCamaras < this.totalCamaras;
  }

  async irResumen(): Promise<void> {
    this._router.navigate(['./formulario/dashboard/resumen']);
  }

  async ngOnInit(){}
}
