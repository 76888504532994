import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ribbon',
  template: `
    <div class="ribbon-2">
      <span>{{title}}</span>
      <label>{{subtitle}}</label>
    </div>
  `,
  styles: [`
    [class^="ribbon-"] {
      position: relative;
      margin-bottom: 20px;
      margin-top: 7px !important;
    }
    [class^="ribbon-"]:before, [class^="ribbon-"]:after {
        content: "";
        position: absolute;
    }
    .ribbon-2 {
      width: max-content;
      height: 40px;
      background: #d11848;
      left: -25px;
      color: #fff;
      border-radius: 0px 10px 10px 0px;
      padding: 5px 20px;
      box-shadow: 0px 0px 10px #b1b1b1;
    }
    .ribbon-2::before {
      height: 0;
      width: 0;
      border-bottom: 8px solid #7b0020;
      border-left: 8px solid transparent;
      top: -8px;
      left: 0px;
    }
    span {
      font-family: Corisande-Bold, "Helvetica Neue", sans-serif;
    }
    label {
        display: block;
        font-weight: 100;
    }
  `]
})

export class RibbonComponent {
  @Input() title: string;
  @Input() subtitle: string;
}
