import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { Observable } from 'rxjs';
import * as _moment from 'moment';
export class HttpProxy {

    private url: string;
    private url2: string;

    constructor(private http: HttpClient, private endpoint: string, private endpoint2?: string) {
        this.url = endpoint;
        this.url2 = endpoint2;
    }

    /** @description Metodo que permite Obtener datos por GET con parametros */
    public get<T>(service: string, queryParam?: string): Observable<T> {
        const headers = this.getHeaders();
        return this.http.get<T>(this.url + service + (queryParam || ''), { headers });
    }

    public getDataParams<T>(service: string, params?): Observable<T> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.get<T>(this.url + service, { headers, params });
    }

    /** @description Metodo que permite Obtener datos por GET path variable */
    public getPath<T>(service: string, path: string): Observable<T> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.get<T>(this.url + service + path, { headers });
    }

    /** @description Metodo que permite Obtener datos por POST */
    public post<T>(service: string, request: any): Observable<T> {
        const headers = this.getHeaders();
        return this.http.post<T>(this.url + service, request, { headers });
    }

    /** @description Metodo que permite Obtener datos por POST */
    public postCargarArchivo<T>(service: string, request: FormData): Observable<T> {
        const headers = this.getHeadersArchivos();
        return this.http.post<T>(this.url + service, request, { headers });
    }

    /** @description Metodo que permite Obtener datos por POST */
    public post2<T>(service: string, request: any): Observable<T> {
        const headers = this.getHeaders();
        return this.http.post<T>(this.url2 + service, request, { headers });
    }

    /** @description Metodo que permite Obtener datos por PUT */
    public put<T>(service: string, request: any): Observable<T> {
        const headers = this.getHeaders();
        return this.http.put<T>(this.url + service, request, { headers });
    }

    /** @description Metodo que permite Obtener datos por PUT */
    public put2<T>(service: string, request: any): Observable<T> {
        const headers = this.getHeaders();
        return this.http.put<T>(this.url2 + service, request, { headers });
    }

    /** @description Metodo que permite enviar una peticion de tipo DELETE */
    public delete<T>(service: string): Observable<T> {
        const headers = this.getHeaders();
        return this.http.delete<T>(this.url + service, { headers });
    }

    /** @description Metodo que permite enviar una peticion de tipo DELETE */
    public deleteBody<T>(service: string, request: any) {
        const headers = this.getHeaders();
        const options = {headers, body: request};
        return this.http.delete(this.url + service, options);
    }

    /** @description Metodo que descarga un pdf */
    public binaryDataZip(service: string, queryParam?: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get(this.url2 + service, { headers, responseType: 'blob' });
    }

    /** @description Metodo que descarga un pdf */
    public binaryDataPDF(service: string, queryParam?: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get(this.url + service, { headers, responseType: 'blob' });
    }

    public axios(method: Method, service: string, data?: any) {
        const _headers = this._createRequestHeaders();
        return axios.request({
            url: this.url + service,
            method: method,
            data: data,
            headers: _headers
        })
    }

    private _createRequestHeaders() {
        const ip = sessionStorage.getItem('ip');
        const idTransaccion = sessionStorage.getItem('idTransaccion');
        const tknQuery = localStorage.getItem('tkn');
        return {
            'Authorization': tknQuery,
            'Content-Type': 'application/json',
            'idTransaccion': idTransaccion,
            'idAplicacion': 'APPNUBE',
            'idModulo': 'RN',
            'ipConsumidor': ip,
            'idConsumidor': 'FRONT',
            'fechaSolicitud': _moment().format('YYYY/MM/DD HH:mm:ss')
        }
    }

    /** @description Metodo que permite agregar cabeceras a la peticion */
    private getHeaders() {
        const ip = sessionStorage.getItem('ip');
        const idTransaccion = sessionStorage.getItem('idTransaccion');
        const tknQuery = localStorage.getItem('tkn');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'idTransaccion': idTransaccion,
            'idAplicacion': 'APPNUBE',
            'idModulo': 'RN',
            'ipConsumidor': ip,
            'idConsumidor': 'FRONT',
            'fechaSolicitud': _moment().format('YYYY/MM/DD HH:mm:ss')
        });

        if (tknQuery) headers = headers.append('Authorization', tknQuery)

        return headers;
    }

    /** @description Metodo que permite agregar cabeceras a la peticion */
    private getHeadersArchivos() {
        const ip = sessionStorage.getItem('ip');
        const idTransaccion = sessionStorage.getItem('idTransaccion');
        const tknQuery = localStorage.getItem('tkn');
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'idTransaccion': idTransaccion,
            'idAplicacion': 'APPNUBE',
            'idModulo': 'RN',
            'ipConsumidor': ip,
            'idConsumidor': 'FRONT',
            'fechaSolicitud': _moment().format('YYYY/MM/DD HH:mm:ss')
        });

        if (tknQuery) headers = headers.append('Authorization', tknQuery)

        return headers;
    }
}
