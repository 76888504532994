import { Component, OnInit } from '@angular/core';
import { LoaderService } from './shared/services/loader.service';
import { version } from '../../package.json';


@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="state" class="flex fullCenter loader">
      <div class="loader__spinner">
        <mat-spinner color="primary"></mat-spinner>
        <h5>En espera ...</h5>
      </div>
    </div>
    <router-outlet></router-outlet>
  `,
  styles: [`
    .loader {
      position: fixed;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background-color: #2b2b2b66;
      z-index: 999;
    }
    .loader__spinner {
      background-color: #ffffff7a;
      padding: 50px 70px;
      box-shadow: 0px 0px 10px #bdbdbd;
      border-radius: 8px;
    }
    h5 {
      text-align: center;
      margin-top: 20px;
      font-size: 20px;
      color: #013467;
    }
  `]
})

export class AppComponent implements OnInit {

  state: boolean;
  version: string = version;

  constructor(private loaderService: LoaderService) {
    this.loaderService.loader.subscribe(async res => this.state = await res);
    console.log('versión aplicación', version);

  }
  ngOnInit() {
  }

}
