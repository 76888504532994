import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `
    <div class="semaforo-theme">
      <p>{{text}}</p>
      <mat-progress-bar
          mode="determinate"
          [color]="color"
          [value]="value"></mat-progress-bar>
    </div>
  `,
  styles: [`
    p {
      color:#565656;
      margin-bottom: 5px;
      font-size: 15px;
    }
  `]
})
export class ProgressBarComponent implements OnChanges {

  @Input() text: string;
  @Input() value: number;
  color: any;

  ngOnChanges() {
    if (!this.value || this.value >= 0 && this.value < 20) {
      this.color = 'accent'; //rojo
    } else if (this.value >= 20 && this.value <= 80) {
      this.color = 'warn'; //amarillo
    } else {
      this.color = 'success'; //verde 100%
    }
  }
}
