import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../interfaces/user.interface';
import { Matriculado } from '../interfaces/matriculado.interface';
import { CamaraCarga, Cuf } from '../interfaces/cuf.interface';
import { MatriculaPrincipal } from '../interfaces/matricula-principal.interface';

@Injectable({
    providedIn: 'root'
})

// Activa el estado de espera - loader
export class DBService {

    private _representante = new BehaviorSubject<User>(null);
    representante = this._representante.asObservable();

    private _numeroMatricula = new BehaviorSubject<string>(null);
    numeroMatricula = this._numeroMatricula.asObservable();

    private _esAfiliado = new BehaviorSubject<boolean>(null);
    esAfiliado = this._esAfiliado.asObservable();

    private _numeroMatriculaEstablecimiento = new BehaviorSubject<string>(null);
    numeroMatriculaEstablecimiento = this._numeroMatriculaEstablecimiento.asObservable();

    private _cuf = new BehaviorSubject<string>(null);
    cuf = this._cuf.asObservable();

    private _codCamara = new BehaviorSubject<string>(null);
    codCamara = this._codCamara.asObservable();

    private _nombreCamara = new BehaviorSubject<string>(null);
    nombreCamara = this._nombreCamara.asObservable();

    private _matriculado = new BehaviorSubject<Matriculado>(null);
    matriculado = this._matriculado.asObservable();

    private _compenExce = new BehaviorSubject<any>(null);
    compenExce = this._compenExce.asObservable();

    private _establecimiento = new BehaviorSubject<Matriculado>(null);
    establecimiento = this._matriculado.asObservable();

    private _cantidadTotalParaPaginacion = new BehaviorSubject<CamaraCarga>(null);
    cantidadEstablecimientosCamara = this._cantidadTotalParaPaginacion.asObservable();

    private _idFormulario = new BehaviorSubject<string>(null);
    idFormulario = this._idFormulario.asObservable();


    private _idFormularioEstablecimiento = new BehaviorSubject<string>(null);
    idFormularioEstablecimiento = this._idFormularioEstablecimiento.asObservable();

    private _esEsal = new BehaviorSubject<boolean>(false);
    esEsal = this._esEsal.asObservable();

    private _dataPago = new BehaviorSubject<any>(null);
    dataPago = this._dataPago.asObservable();

    private _idOrganizacion = new BehaviorSubject<string>(null);
    idOrganizacion = this._esEsal.asObservable();

    private _idOrganizacionPrincipal = new BehaviorSubject<string>(null);
    idOrganizacionPrincipal = this._esEsal.asObservable();

    private _token = new BehaviorSubject<string>(null);
    token = this._token.asObservable();

    private _callPorcentajeRues = new BehaviorSubject<boolean>(false);
    callPorcentajeRues = this._callPorcentajeRues.asObservable();

    private _cufSeleccionadoFormularios = new BehaviorSubject<Cuf>(null);
    cufSeleccionadoFormularios = this._cufSeleccionadoFormularios.asObservable();

    private _diligenciados = new BehaviorSubject<any>([]);
    diligenciados = this._diligenciados.asObservable();

    private _matriculaPrincipal = new BehaviorSubject<MatriculaPrincipal>(null);
    matriculaPrincipal = this._matriculaPrincipal.asObservable();

    private _notificaciones = new BehaviorSubject<any>([]);
    notificaciones = this._notificaciones.asObservable();

    private _ctrCargaEnProceso = new BehaviorSubject<number>(null);
    ctrCargaEnProceso = this._ctrCargaEnProceso.asObservable();

    private _ctrRues = new BehaviorSubject<number>(null);
    ctrRues = this._ctrRues.asObservable();

    public getRespresentante(): User {
        return this._representante.value;
    }

    public setRespresentante(respresentante: User) {
        this._representante.next(respresentante);
    }

    public setNumeroMatricula(matricula: string) {
        this._numeroMatricula.next(matricula);
    }

    public getNumeroMatricula(): string {
        return this._numeroMatricula.value;
    }

    public setNumeroMatriculaEstablecimiento(matricula: string) {
        this._numeroMatriculaEstablecimiento.next(matricula);
    }

    public getNumeroMatriculaEstablecimiento(): string {
        return this._numeroMatriculaEstablecimiento.value;
    }

    public getEsAfiliado(): boolean {
        return this._esAfiliado.value;
    }

    public setEsAfiliado(afiliado: boolean) {
        this._esAfiliado.next(afiliado);
    }

    public setCuf(cuf: string) {
        this._cuf.next(cuf);
    }

    public getCodCamara(): string {
        return this._codCamara.value;
    }

    public setCodCamara(cod: string) {
        this._codCamara.next(cod);
    }

    public getNombreCamara(): string {
        return this._nombreCamara.value;
    }

    public setNombreCamara(nombre: string) {
        this._nombreCamara.next(nombre);
    }

    public getCuf(): string {
        return this._cuf.value;
    }

    public setMatriculado(matriculado: Matriculado) {
        this._matriculado.next(matriculado);
    }

    public getMatriculado(): Matriculado {
        return this._matriculado.value;
    }

    public setCompensacionExcedentes(excedente, compensacion, valor) {
        this._compenExce.next({exce: excedente, compe: compensacion, valorCompe: valor});
    }

    public getCompensacionExcedentes(): Matriculado {
        return this._compenExce.value;
    }

    public setEstablecimiento(establecimiento: any) {
        this._establecimiento.next(establecimiento);
    }

    public getEstablecimiento(): any {
        return this._establecimiento.value;
    }

    public setCantidadEstablecimientosCamara(codCamara: string, countEstablecimientos: number, esPrincipal?: boolean) {
        const _camaraCarga: CamaraCarga = { codCamara, countEstablecimientos, esPrincipal };
        this._cantidadTotalParaPaginacion.next(_camaraCarga);
    }

    public getCantidadEstablecimientosCamara(): CamaraCarga {
        return this._cantidadTotalParaPaginacion.value;
    }

    public setIdFormulario(idFormulario: string) {
        this._idFormulario.next(idFormulario);
    }

    public getIdFormulario(): string {
        return this._idFormulario.value;
    }

    public setIdFormularioEstablecimiento(idFormulario: string) {
        this._idFormularioEstablecimiento.next(idFormulario);
    }

    public getIdFormularioEstablecimiento(): string {
        return this._idFormularioEstablecimiento.value;
    }

    public setToken(token: string) {
        this._token.next(token);
    }

    public getToken(): string {
        return this._token.value;
    }

    public setEsEsal(state: boolean) {
        this._esEsal.next(state);
    }

    public getEsEsal(): boolean {
        return this._esEsal.value;
    }

    public setDataPago(state: any) {
        this._dataPago.next(state);
    }

    public getDataPago(): any {
        return this._dataPago.value;
    }

    public SetIdOrganizacion(state: string) {
        this._idOrganizacion.next(state);
    }

    public getIdOrganizacion(): string {
        return this._idOrganizacion.value;
    }

    public SetIdOrganizacionPrincipal(state: string) {
        this._idOrganizacionPrincipal.next(state);
    }

    public getIdOrganizacionPrincipal(): string {
        return this._idOrganizacionPrincipal.value;
    }

    public setCallPorcentajeRues(state: boolean) {
        this._callPorcentajeRues.next(state);
    }

    public setDiligenciados(state) {
        this._diligenciados.next(state);
    }

    public getDiligenciados() {
        return this._diligenciados.value;
    }

    public setMatriculaPrincipal(state) {
        this._matriculaPrincipal.next(state);
    }

    public getMatriculaPrincipal() {
        return this._matriculaPrincipal.value;
    }


    public setNotificaciones(state) {
        this._notificaciones.next(state);
    }

    public getNotificaciones() {
        return this._notificaciones.value;
    }

    public setCtrRues(state) {
        this._ctrRues.next(state);
    }

    public getCtrRues() {
        return this._ctrRues.value;
    }

    public setCtrCargaEnProceso(state) {
        this._ctrCargaEnProceso.next(state);
    }

}
